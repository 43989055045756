/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const Slide = ({ content, isMobile }) => (
  <div
    css={css`
      height: 100%;
      width: 100%;
      background-image: url('${content}');
      background-size: ${isMobile ? 'auto 100%' : 'cover'};
      background-repeat: no-repeat;
      background-position: ${isMobile ? 'center' : 'left center'};
    `}
  />
);

export default Slide;
