import React, { useState, useEffect } from 'react';
import SliderContent from './sliderContent';
import Slide from './slide';
import Arrow from './arrow';
import './slider.css';

/********Esta linea nos va a morder la nalga, con amor Ben del pasado************/
const getWidth = (isMobile, setWidth) => {
  if (typeof window !== 'undefined') {
    setWidth(isMobile && window.innerWidth > 434 ? 434 : window.innerWidth);
  } else {
    setWidth(434);
  }
}

const Slider = ({ isMobile, slides }) => {
  const [width, setWidth] = useState(1024);
  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45
  })
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      getWidth(isMobile, setWidth);
      window.addEventListener('resize', () => getWidth(isMobile, setWidth));
    }
  }, [isMobile]);

  const { translate, transition, activeIndex } = state

  const nextSlide = () => {
    if (activeIndex === slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0
      })
    }

    setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * width
    })
  }

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: (slides.length - 1) * width,
        activeIndex: slides.length - 1
      })
    }

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: (activeIndex - 1) * width
    })
  }
  
  return (
    <div className="slider">
      <SliderContent
        translate={translate}
        transition={transition}
        width={width * slides.length}
      >
        {slides.map((slide, i) => (
          <Slide key={slide + i} content={slide} isMobile={isMobile} />
        ))}
      </SliderContent>

      <Arrow direction="left" handleClick={prevSlide} />
      <Arrow direction="right" handleClick={nextSlide} />
    </div>
  )
}

export default Slider