import React from 'react'
import leftArrow from '../../images/arrow-left.svg'
import rightArrow from '../../images/arrow-right.svg'

const Arrow = ({ direction, handleClick }) => (
  <div
    className={`slider_arrow slider_arrow_${direction}`}
    onClick={handleClick}
    onKeyDown={handleClick}
    role="button"
    tabIndex="0"
  >
    {
    direction === 'right'
    ?
    <img className={`slider_arrow_icon slider_arrow_icon_${direction}`} src={rightArrow} alt="derecha"/>
    :
    <img className={`slider_arrow_icon slider_arrow_icon_${direction}`} src={leftArrow} alt="izquierda" />
    }
  </div>
)

export default Arrow