import React from 'react';
//Material UI
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: '#191919',
    boxShadow: '0 16px 40px 0 rgba(108, 108, 108, 0.5)',
    border: 'solid 2px #6c6c6c',
    color: '#ffffff',
    fontSize: '14px',
    padding: '0.2rem',
    width: '100%',
  },
  inputContainer: {
    paddingLeft: '10px',
  },
  label: {
    background: theme.palette.primary.main,
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: '0.2rem',
  },
  labelTriangle: {
    borderTop: `26.59px solid ${theme.palette.primary.main}`,
    borderLeft: '10px solid transparent',
  }
}));

export default ({label, onChange, type, value}) => {
  const classes = useStyles();
  return (
    <Grid container justify="flex-start" spacing={0}>
      <Grid className={classes.labelTriangle} item></Grid>
      <Grid className={classes.label} item>
        <label>{label}</label>
      </Grid>
      <Grid className={classes.inputContainer} item xs={12}>
        <input
          className={classes.input}
          onChange={onChange}
          type={type}
          value={value}
        />
      </Grid>
    </Grid>
  );
}